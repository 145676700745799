import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CourseService } from '../shared/services/course.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-reset-module',
  templateUrl: './reset-module.component.html',
  styleUrls: ['./reset-module.component.scss'],
  providers: [MessageService]
})
export class ResetModuleComponent implements OnInit {
  activeIndex = 0
  options = ['Test Data', 'Flashcards', 'My Notebook']
  values = [
    '<ul><li>Resetting Test Data will DELETE ALL data related to notes, highlights, performance and all previous tests.</li><li><strong>Unlimited, irreversible change.</strong></li></ul>',
    '<ul><li>Resetting Flashcards will DELETE ALL data related to decks, cards, and all study progress.</li><li><strong>Unlimited, irreversible change.</strong></li></ul>',
    '<ul><li>Resetting My Notebook will DELETE ALL notes and their respective contents.</li><li><strong>Unlimited, irreversible change.</strong></li></ul>',
  ]
  buttons = ['Reset Test Data', 'Reset Flashcards', 'Reset My Notebook']
  displayTimer = false
  timer = 20
  timerCheck = false
  interval: any;
  subscriptions: any = [];
  courses = [];

  constructor(private courseService: CourseService, private authService: AuthService, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.getUserSubscription()
  }

  changeIndex(index: any) {
    this.activeIndex = index 
  }

  getUserSubscription(){
    this.authService.getUserSubscription().subscribe((res: any) => {
      if (res.subscriptions.length > 0) {
        res.subscriptions.forEach(element => {
          if (element.Status == 'active') {
            if (element.MembershipID === 1 || element.MembershipID === 5) {
              this.courses.push({
                ID: 115014,
                Title: 'Step 1'
              })
            }
            if (element.MembershipID === 7 || element.MembershipID === 10 || element.MembershipID === 14 || element.MembershipID === 15) {
              this.courses.push({
                ID: 115015,
                Title: 'Step 2 CK'
              })
            }
            if (element.MembershipID === 11 || element.MembershipID === 12) {
              this.courses.push({
                ID: 115014,
                Title: 'Step 1'
              })
              this.courses.push({
                ID: 115015,
                Title: 'Step 2 CK'
              })
            }
          }
        });

        this.courses = [...new Map(this.courses.map(item => [item['ID'], item])).values()];
      }
    })
  }

  resetContent(courseId: any) {
    if (this.activeIndex == 0) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to reset your test data?',
        accept: () => {
          this.timerCheck = true
          this.displayTimer = true
          this.interval = setInterval(() => {
            this.timer --
            if (this.timer == 0) {
              this.timerCheck = false
              this.displayTimer = false
              clearInterval(this.interval)
              this.timer = 20
              this.courseService.resetData(courseId).subscribe((res: any) => {
                this.messageService.add({severity:'success', summary:'Reset Successfully', detail:'Your test data has been resetted.'});
                localStorage.removeItem('prevQuizData')
              })
            }
          }, 1000)
        },
        reject: () => {
        }
      })
    }
    else if (this.activeIndex == 1) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to reset your flashcards?',
        accept: () => {
          this.timerCheck = true
          this.displayTimer = true
          this.interval = setInterval(() => {
            this.timer --
            if (this.timer == 0) {
              this.timerCheck = false
              this.displayTimer = false
              clearInterval(this.interval)
              this.timer = 20
              this.courseService.resetFlashcards().subscribe((res: any) => {
                this.messageService.add({severity:'success', summary:'Reset Successfully', detail:'Your flashcards has been resetted.'});
              })
            }
          }, 1000)
        },
        reject: () => {
        }
      })
    }
    else if (this.activeIndex == 2) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to reset your notebook?',
        accept: () => {
          this.timerCheck = true
          this.displayTimer = true
          this.interval = setInterval(() => {
            this.timer --
            if (this.timer == 0) {
              this.timerCheck = false
              this.displayTimer = false
              clearInterval(this.interval)
              this.timer = 20
              this.courseService.resetNotebook().subscribe((res: any) => {
                this.messageService.add({severity:'success', summary:'Reset Successfully', detail:'Your notebook has been resetted.'});
              })
            }
          }, 1000)
        },
        reject: () => {
        }
      })
    }
  }

  stopTimer() {
    this.displayTimer = false
    this.timerCheck = false
    clearInterval(this.interval)
    this.timer = 20
  }
}
